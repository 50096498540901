import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { routes } from "./functions/routes";
import Home from "./pages/Home";

import toast, { Toaster } from 'react-hot-toast';

//import "bootstrap/dist/css/bootstrap.min.css";

import { useEffect } from "react";

import Profile from "./pages/Profile";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";

import Course from "./pages/Course";
import Lesson from "./pages/Lesson";
import Policy from "./components/Policy";

import Courses from "./pages/Courses";

function App() {
  useEffect(() => {
    toast.dismiss();
  }, [])
  return (
    <div className="App">
      <Toaster />
      <BrowserRouter>
        <Routes>
          <Route path={routes.root} element={<Home />} />
          <Route path={routes.home} element={<Home />} />
          <Route path={routes.courses} element={<Courses />} />
          <Route path={routes.profile} element={<Profile />} />

          <Route path={routes.us} element={<AboutUs />} />
          <Route path={routes.contacts} element={<ContactUs />} />
          <Route path={routes.course + "/:organization/:course"} element={<Course />} />
          <Route path={routes.lesson + "/:actualCourse/:lesson_type/:lesson_id"} element={<Lesson />} />

          <Route path={routes.policy} element={<Policy page />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
