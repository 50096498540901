import toast from "react-hot-toast";

//Dev

let dev = {
    URL: {
        home: "http://192.168.100.176:8080/",
        homeAPI: "http://192.168.100.176:8080/" + "api/",
        homePublic: "http://192.168.100.176:8080/" + "",
        videoStreamURL: "http://192.168.100.176:8080/" + "api/" + "video_stream.php?",
        pdfStreamURL: "http://192.168.100.176:8080/" + "api/" + "pdf_stream.php?"
    }
}

//PREPROD

let prod = {
    URL: {
        home: "https://dev.creamosjuntos.org/",
        homeAPI: "https://dev.creamosjuntos.org/" + "api/",
        homePublic: "https://dev.creamosjuntos.org/" + "",
        videoStreamURL: "https://dev.creamosjuntos.org/" + "api/" + "video_stream.php?",
        pdfStreamURL: "https://dev.creamosjuntos.org/" + "api/" + "pdf_stream.php?"
    }
}

//PROD
/*
let prod = {
    URL: {
        home: "https://creamosjuntos.org/",
        homeAPI: "https://creamosjuntos.org/" + "api/",
        homePublic: "https://creamosjuntos.org/" + "",
        videoStreamURL: "https://creamosjuntos.org/" + "api/" + "video_stream.php?",
        pdfStreamURL: "https://creamosjuntos.org/" + "api/" + "pdf_stream.php?"
    }
}
*/

let URL = (process.env.NODE_ENV === "production")? prod.URL : dev.URL;


export const home = URL.home;
export const homeAPI = URL.homeAPI;
export const homePublic = URL.homePublic;
export const videoStreamURL = URL.videoStreamURL;
export const pdfStreamURL = URL.pdfStreamURL;

//DBLOCAL
export const lastLessonStorage = "LAST_LESSON";

//Sizes
export const mobile = 768;
export const tablet = 992;
export const mini = 400;

//Categories
export const categorieImages: { [key: string]: any } = {
    "tech": {
        categorieText: "Tecnologia",
        bgImage: "https://hci-aws-media.s3-accelerate.amazonaws.com/2020/07/connected-technology.jpg",
    }
    ,
    "admin": {
        categorieText: "Administración",
        bgImage: "https://image.shutterstock.com/image-photo/business-administrator-negotiation-discussing-about-260nw-1317506042.jpg",
    }
    ,
    "eco": {
        categorieText: "Medio Ambiente",
        bgImage: "https://st6.cannypic.com/thumbs/47/473985_632_canny_pic.jpg",
    }

}

//MESSAGES

export const errorMessagePost = "Ha ocurrido un error, porfavor intenta nuevamente  más tarde";

export const onCatch = (err: any) => {
    toast.dismiss();
    toast.error(errorMessagePost)
}