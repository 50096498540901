import { route } from "./types";
import { home } from "./variables";

export const routes:route = {
    root: "/",
    home: "/inicio",
    courses: "/cursos",

    course: "/course",
    lesson: "/lesson",

    us: "/nosotros",
    contacts: "/contactos",
    login: "/login",
    logout: "/logout",
    logup: "/logup",
    profile: "/profile",
    course_manager: "/course_manager",
    policy: "/policy",
    instructors: "/instructors"
}

/**
 * 
 * @param name Name of organization
 * @returns Public path of organization content
 */
export const organizationPath = (name: string) =>{
    return home.concat("organizations/").concat(name).concat("/");
}

/**
 * 
 * @param name Name of organization
 * @returns Path of public images of courses and others
 */
export const organizationCoursesPath = (name: string) => {
    return organizationPath(name).concat("courses/");
}

/**
 * 
 * @param name Name of organization
 * @returns Path of public images of instructors
 */
export const organizationInstructorsPath = (name: string) => {
    return organizationPath(name).concat("instructors/");
}